.button-toggle
  @apply bg-tertiary text-white flex items-center text-left justify-between py-2 px-5 transition-colors duration-150 mt-6 mb-2 w-full z-20 relative
  font-family: Poppins
  font-style: normal
  font-weight: 500
  font-size: 24px
  line-height: 1.25
  letter-spacing: -0.01em

  svg
    @apply transition-transform duration-200

  &:hover,
  &:focus
    @apply bg-green

  + div
   @apply opacity-0 invisible absolute

  &--open
    svg
      transform: rotateX(180deg)

    + div
      @apply opacity-100 visible relative transition-all duration-300
