$timelineHeight: 1040px
$timelineWidth: 3150px

body
  @apply bg-black text-white

.timeline-wrap
  background: url(../assets/bg.jpg) 0 0

.timeline
  @apply text-black
  background: linear-gradient(0deg, rgba(#fff, 1) 0%, rgba(0,0,0, 0) 40px)
  // height: calc(100vh - 58px)
  // height: calc(var(--vh, 1vh) * 100 - 58px)
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  max-height: $timelineHeight

.city,
.clouds,
.trees,
.trees-container
  @apply absolute top-0 left-0 min-h-full
  background: url(../assets/city.png) 0 100% repeat-x
  width: $timelineWidth

.clouds
  background-image: url(../assets/clouds.png)
  background-position: 0 0

.grass
  background: url(../assets/grass.png) 0 100% repeat-x

.trees
  background: url(../assets/trees.png) 0 100% repeat-x
  width: 100%

.people
  background: url(../assets/people.png) 0 100% no-repeat
  position: relative
  z-index: 20

.journey
  @apply flex flex-no-wrap justify-evenly items-end
  margin-bottom: 300px
  width: $timelineWidth

  @media (min-height: 700px)
    margin-bottom: 400px

.strip
  @apply absolute
  background: rgba(#fff, .62)
  bottom: 0
  display: flex
  left: 0
  height: 50px
  overflow: hidden
  width: 2830px

  @screen md
    height: 68px

  & > div
    background: url(../assets/strip-arrow.png) 0 50% no-repeat
    background-size: 30px 11px
    height: 100%
    width: 30px

  @for $i from 1 through 16
    div:nth-child(#{$i})
      animation: pulse 3s linear infinite
      animation-delay: #{$i * 0.1}s

@keyframes pulse
  0%, 70%, 100%
    opacity: 1
  33%, 50%
    opacity: .1

@media (min-height: 700px)
  .journey
    margin-bottom: calc(var(--vh, 1vh) * 50 + 100px)

@media (min-height: 780px)
  .journey
    margin-bottom: calc(var(--vh, 1vh) * 50 + 200px)

// @media (min-height: 900px)
//   .journey
//     margin-bottom: 580px

.icon
  @apply flex-auto flex-grow relative

  &.open
    z-index: 20

  button
    @apply transition-colors duration-200 relative outline-none
    color: #321D49

    &:hover,
    &:focus
      color: #C00A78

  &__copy
    font-family: Poppins
    font-style: normal
    font-weight: 800
    font-size: 18px
    line-height: 25px
    // max-width: 160px
    max-width: 130px
    position: relative
    text-align: left
    z-index: 2

    @screen md
      font-size: 26px
      line-height: 34px
      max-width: 150px

    span
      @apply bg-secondary text-white inline
      box-decoration-break: clone
      padding: 0px 6px

    svg
      @apply inline-block absolute bg-secondary left-0 top-100

  &__art
    --left: 50%
    --transform-x: -50%
    --transform-y: 0
    --transform-scale: 1
    @apply absolute
    bottom: 100%
    left: var(--left)
    transform: translate(var(--transform-x), var(--transform-y)) scale(var(--transform-scale))
    transition: transform .2s ease-in
    transform-origin: center bottom

    .about-pumps &
      --transform-x: -113px

    .basis-for-action &
      --transform-y: 22%
      --transform-x: -88px

    .searching-opportunities &
      --transform-y: 12%
      --transform-x: -80px

    .estimating-costs &
      --transform-y: 10%
      --transform-x: -73%

    .assessing-opportunities &
      --transform-x: -67%

  &:hover
    .icon__copy
      span,
      svg
        @apply bg-primary transition-colors duration-150

    .icon__art
      --transform-scale: 1.05

  @screen md
    --transform-scale: 1.1

    &:hover
      .icon__art
        --transform-scale: 1.16

.info
  @apply p-4 bg-white absolute opacity-0 invisible transition-opacity
  --bg-opacity: .98
  font-family: Poppins
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 1.2
  letter-spacing: -0.01em
  left: 16px
  margin-top: -1px
  max-width: 90vw
  min-width: 300px
  top: 100%

  @media (max-height: 699px)
    max-height: 250px

  li
    @apply mb-3

    &:last-of-type
      @apply mb-0

  a
    color: inherit
    text-decoration: none

    &:hover,
    &:focus,
    &.active-iframe
      @apply text-primary underline

  .open &
    @apply opacity-100 visible duration-300

.intro.transition
  transition: transform .3s ease-in

.intro.show
  transition: transform .3s ease-out
  transform: translate3d(0, 0, 0) !important

  .collapse-button
    transform: translate3d(0, 0, 0) !important

// https://lea.verou.me/2012/04/background-attachment-local/
.overflow-shadow
  @apply overflow-auto
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%
  background-repeat: no-repeat
  background-color: white
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px
  /* Opera doesn't support this in the shorthand
  background-attachment: local, local, scroll, scroll

a
  @apply text-primary underline

  &:hover,
  &:focus
    @apply no-underline

.footer
  @apply flex justify-center pb-12
  font-family: Poppins
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 30px
  letter-spacing: 0.2em

  a
    @apply no-underline mx-2
    color: #8E005B

    &:hover,
    &:focus
      @apply underline

label
  display: block
  font-family: Poppins
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 120%
  letter-spacing: -0.01em
  text-align: left

input.email
  border: solid 1px #A1A4B1
  border-radius: 4px
  display: block
  font-style: normal
  font-weight: normal
  font-size: 15px
  height: 48px
  line-height: 25px
  margin: 8px 0 12px
  padding: 10px 15px
  width: 100%

.styled-checkbox
  margin-right: 10px
  position: relative

  input
    left: 0
    opacity: 0.01
    position: absolute
    top: 0

    + span
      &::before
        border: solid 2px #000
        border-radius: 2px
        display: block
        content: ''
        height: 18px
        width: 18px

      &::after
        background: #000
        content: ''
        height: 12px
        left: 50%
        opacity: 0.4
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        width: 12px

  input:checked + span::after
    opacity: 1

.submit-button
  @apply bg-green inline-block px-4 py-2 text-white transition-all duration-150 transform mt-2 my-4
  font-size: 24px
  line-height: 30px
  letter-spacing: -0.01em

  &:hover,
  &:focus
    @apply shadow-lg -translate-y-px

.pp-body
  font-family: Poppins
  font-style: normal
  font-weight: 500
  font-size: 20px
  line-height: 30px
  letter-spacing: -0.01em

.start-exploring
 @apply text-black underline

 &:hover,
 &:focus
   @apply no-underline

.min-w-start
  max-width: 700px
  width: 120vw

.js-frame-container
  max-width: 964px
  width: calc(100vw - 60px)
  transform: translate3d(-100%, 0, 0) translateX(-52px)
  transition: transform .3s ease-in

  &.open
    transform: translate3d(0, 0, 0)
    transition: transform .3s ease-out