body
  color: #311D48
  font-family: Poppins
  font-style: normal
  font-weight: 500
  font-size: 20px
  line-height: 30px
  letter-spacing: -0.01em

h1
  @apply text-green
  font-family: Poppins
  font-style: normal
  font-weight: 900
  font-size: 34px
  line-height: 1.25

  @screen md
    @apply mb-4
    font-size: 60px
    line-height: 1

h2
  @apply text-green
  font-family: Poppins
  font-style: normal
  font-weight: 900
  font-size: 28px
  line-height: 1.5

  @screen md
    font-size: 48px

p
  @apply my-6

.strapline
  @apply text-green
  font-family: Poppins
  font-style: normal
  font-weight: 500
  font-size: 22px
  line-height: 1.25
  letter-spacing: -0.01em

  @screen md
    font-size: 24px

.collapse-button
  @apply transition-colors duration-200 text-green

  &:hover
    @apply text-black